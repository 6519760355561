/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  getFunctions,
  getPosition,
  consultPosition,
} from '../services/services';
import { Precios, Map } from '../components';
import MapaC from './mapa';
import MapaV from './mapa/mapaV';
import WaitingIn from './cola/waitingIn';

moment.locale('es');

const Funciones = ({ moneda, setMoneda, monedas, allowBuy }) => {
  const [funciones, setFunciones] = useState([]);
  const [Verificando, setVerificando] = useState(false);
  const [funcionSel, setFuncionSel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataSIM, setDataSIM] = useState(null);
  const timer = React.useRef();
  const cola = false;

  useEffect(() => {
    handleFunciones();
  }, []);

  useEffect(() => {
    if ((!cola || (dataSIM && dataSIM.is_allowed)) && funcionSel !== null) {
      if (funcionSel.tipo === 'boton') {
        if (
          funcionSel.entradas[moneda] &&
          funcionSel.entradas[moneda].trim() !== ''
        ) {
          document.location.href = cola
            ? `${funcionSel.entradas[moneda]}/?host=${dataSIM.host || 0}`
            : `${funcionSel.entradas[moneda]}/`;
          setFuncionSel(null);
          setDataSIM(null);
        }
      } else {
        console.log(funcionSel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSIM, funcionSel]);

  const handleFunciones = async () => {
    const data = await getFunctions();
    if (data) {
      setFunciones(
        data.map((f) => {
          f.verMapa = false;
          return f;
        })
      );
    }
    setLoading(false);
  };

  const handleClick = async (f) => {
    setFuncionSel(f);
    if (!dataSIM && cola) {
      setVerificando(true);
      const data = await getPosition(uuidv4(), f.ciudad);
      if (data) {
        setDataSIM(data);
        if (!data.is_allowed) {
          timer.current = setInterval(async () => {
            refreshInfo(data.host);
          }, data.refresh_in_milliseconds);
        }
      } else {
        setDataSIM({ is_allowed: true });
      }
    }
  };
  const refreshInfo = async (host) => {
    let aux = await consultPosition(host);
    if (aux) {
      setDataSIM(aux);
      if (aux.is_allowed) {
        clearInterval(timer.current);
      }
    }
  };

  const getEstatus = (func) => {
    let classCss = '';
    let textBtn = 'Tickets';
    if (func.tipo === 'boton') {
      classCss =
        !func.entradas[moneda] ||
        func.entradas[moneda] === '' ||
        func.entradas[moneda].trim() === '' ||
        func.entradas[moneda].trim() === 'agotado'
          ? 'agotado'
          : '';
      textBtn =
        !func.entradas[moneda] ||
        func.entradas[moneda] === '' ||
        func.entradas[moneda].trim() === '' ||
        !allowBuy
          ? func.textProx
            ? func.textProx
            : 'Proximamente'
          : func.entradas[moneda].trim() === 'agotado'
          ? func.textAgo
            ? func.textAgo
            : 'Agotado'
          : func.textTk
          ? func.textTk
          : 'Tickets';
    } else {
      classCss =
        func.entradas[moneda] &&
        typeof func.entradas[moneda] === 'object' &&
        Object.keys(func.entradas[moneda]).filter(
          (n) =>
            func.entradas[moneda][n].trim() !== 'agotado' &&
            func.entradas[moneda][n].trim() !== ''
        ).length === 0
          ? 'agotado'
          : '';
      textBtn =
        func.entradas[moneda] &&
        typeof func.entradas[moneda] === 'object' &&
        Object.keys(func.entradas[moneda]).filter(
          (n) => func.entradas[moneda][n].trim() !== 'agotado'
        ).length > 0
          ? func.entradas[moneda] &&
            typeof func.entradas[moneda] === 'object' &&
            Object.keys(func.entradas[moneda]).filter(
              (n) => func.entradas[moneda][n].trim() !== ''
            ).length > 0 &&
            allowBuy
            ? 'TICKETS'
            : func.textProx
            ? func.textProx
            : 'TICKETS'
          : 'AGOTADO';
    }
    return { classCss, textBtn };
  };

  return loading ? (
    <div></div>
  ) : (
    <section className="ld--wrap-funciones">
      <div className="ld--wrap-botones-titles d-flex justify-content-between">
        <h1 className="ld--title">Funciones</h1>
        {monedas.length > 1 && (
          <div className="ld--monedas">
            {monedas.map((m) => (
              <span
                key={m}
                className={moneda === m ? 'active' : ''}
                onClick={() => setMoneda(m)}
              >
                Pagar en {m === 'VES' ? 'VEF' : m}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="ld--funciones">
        {funciones.map((f, idx) => {
          const { classCss, textBtn } = getEstatus(f);
          let ending = moment(
            moment(f.fecha, 'DD/MM/YYYY h:mm A').format('YYYY-MM-DD')
          ).isBefore(moment().format('YYYY-MM-DD'));
          return (
            !ending &&
            !f.especial && (
              <div className="ld--card" key={idx}>
                <div className="ld--item-funcion">
                  <div className="ld--equipos">
                    <span className="ld--ciudad">{f.ciudad}</span>
                  </div>
                  <div className="ld--lugar">
                    <span className="ld--recinto">{f.recinto}</span>
                    <span className="ld--fecha">
                      {moment(f.fecha, 'DD/MM/YYYY hh:mmA').format(
                        'DD/MM/YYYY, hh:mm a'
                      )}
                    </span>
                    {f.puertas ? (
                      <span className="ld--puertas">
                        Apertura de puertas{' '}
                        {moment(f.puertas, 'DD/MM/YYYY hh:mmA').format(
                          'hh:mm a'
                        )}
                      </span>
                    ) : null}
                  </div>
                  <div className="ld--acciones">
                    <button
                      className={`ld--btn ${classCss} ${
                        classCss === 'agotado' ||
                        !allowBuy ||
                        (Verificando && funcionSel && f.id === funcionSel.id)
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => handleClick(f)}
                      disabled={
                        classCss === 'agotado' ||
                        !allowBuy ||
                        (Verificando && funcionSel && f.id === funcionSel.id)
                      }
                    >
                      {Verificando && funcionSel && f.id === funcionSel.id ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>{textBtn}</span>
                      )}
                    </button>
                    <a
                      href="#"
                      className="verMapa"
                      onClick={(e) => {
                        e.preventDefault();
                        setFunciones((prev) => {
                          return prev.map((fx, index) => {
                            return {
                              ...fx,
                              verMapa: f.id === fx.id && !fx.verMapa,
                            };
                          });
                        });
                      }}
                    >
                      {f.verMapa
                        ? 'Ocultar detalles y precios'
                        : 'Ver detalles y precios'}
                    </a>
                  </div>
                </div>
                {f.verMapa && (
                  <div className="ld-det-mapa-prec">
                    <div className="row">
                      <div className="col col-12 col-md-6">
                        {f.mapa ? (
                          <img
                            src={process.env.REACT_APP_URL_IMAGES + f.mapa}
                            alt=""
                          />
                        ) : f.iframe ? (
                          <Map src={f.iframe} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="col col-12 col-md-6">
                        {<Precios precios={f.precios} fees={false} />}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          );
        })}
      </div>

      {funciones.filter((f) => f.especial === true).length > 0 && (
        <>
          <h1 className="ld--title">Función especial</h1>
          <div className="ld--funciones">
            {funciones
              .filter((f) => f.especial === true)
              .map((f, idx) => {
                const { classCss, textBtn } = getEstatus(f);
                let ending = moment(
                  moment(f.fecha, 'DD/MM/YYYY h:mm A').format('YYYY-MM-DD')
                ).isBefore(moment().format('YYYY-MM-DD'));
                return (
                  !ending && (
                    <div className="ld--card" key={idx}>
                      <div className="ld--item-funcion">
                        <div className="ld--equipos">
                          <span className="ld--ciudad">{f.ciudad}</span>
                          {f.logo_funcion && f.logo_funcion.trim() !== '' ? (
                            <img
                              src={`${process.env.REACT_APP_URL_IMAGES}${f.logo_funcion}`}
                              className="logo-funcion" alt='logo funcion'
                            />
                          ) : null}
                        </div>
                        <div className="ld--lugar">
                          <span className="ld--recinto">{f.recinto}</span>
                          <span className="ld--fecha">
                            {moment(f.fecha, 'DD/MM/YYYY hh:mmA').format(
                              'DD/MM/YYYY, hh:mm a'
                            )}
                          </span>
                          {f.puertas ? (
                            <span className="ld--puertas">
                              Apertura de puertas{' '}
                              {moment(f.puertas, 'DD/MM/YYYY hh:mmA').format(
                                'hh:mm a'
                              )}
                            </span>
                          ) : null}
                        </div>
                        <div className="ld--acciones">
                          <button
                            className={`ld--btn ${classCss} ${
                              classCss === 'agotado' ||
                              !allowBuy ||
                              (Verificando &&
                                funcionSel &&
                                f.id === funcionSel.id)
                                ? 'disabled'
                                : ''
                            }`}
                            onClick={() => handleClick(f)}
                            disabled={
                              classCss === 'agotado' ||
                              !allowBuy ||
                              (Verificando &&
                                funcionSel &&
                                f.id === funcionSel.id)
                            }
                          >
                            {Verificando &&
                            funcionSel &&
                            f.id === funcionSel.id ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <span>{textBtn}</span>
                            )}
                          </button>
                          <a
                            href="#"
                            className="verMapa"
                            onClick={(e) => {
                              e.preventDefault();
                              setFunciones((prev) => {
                                return prev.map((fx, index) => {
                                  return {
                                    ...fx,
                                    verMapa: fx.id === f.id && !fx.verMapa,
                                  };
                                });
                              });
                            }}
                          >
                            {f.verMapa
                              ? 'Ocultar detalles y precios'
                              : 'Ver detalles y precios'}
                          </a>
                        </div>
                      </div>
                      {f.verMapa && (
                        <div className="ld-det-mapa-prec">
                          <div className="row">
                            <div className="col col-12 col-md-6">
                              {f.mapa ? (
                                <img
                                  src={
                                    process.env.REACT_APP_URL_IMAGES + f.mapa
                                  }
                                  alt=""
                                />
                              ) : f.iframe ? (
                                <Map src={f.iframe} />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="col col-12 col-md-6">
                              {<Precios precios={f.precios} fees={false} />}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
          </div>
        </>
      )}

      {funcionSel &&
        funcionSel.tipo === 'mapa' &&
        (!cola || (dataSIM && dataSIM.is_allowed)) &&
        (funcionSel.id === 1 ? (
          <MapaC
            closeModal={() => {
              setFuncionSel(null);
            }}
            entradas={funcionSel.entradas[moneda]}
            moneda={moneda}
            host={dataSIM?.host}
            onClick={(value) => {
              console.log(value);
            }}
          />
        ) : (
          <MapaV
            closeModal={() => {
              setFuncionSel(null);
            }}
            entradas={funcionSel.entradas[moneda]}
            moneda={moneda}
            host={dataSIM?.host}
            onClick={(value) => {
              console.log(value);
            }}
          />
        ))}
      {funcionSel && dataSIM && !dataSIM.is_allowed && (
        <WaitingIn
          setMoneda={setMoneda}
          moneda={moneda}
          // position={300}
          // time={1800}
          // callback={() => refreshInfo(dataSIM.host)}
          position={dataSIM.position}
          time={dataSIM.eta_in_seconds}
          callback={() => refreshInfo(dataSIM.host)}
        />
      )}
    </section>
  );
};

export default Funciones;
